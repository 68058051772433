import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const actions = {
    async listStoresPaginated({ commit, dispatch }, { complete, page }) {
        try {
            const { data } = await Http.instance.get("/bo/stores", {
                params: { complete, page }
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_STORES", parseError);
            throw parseError;
        }
    },
    async getStoreById({ commit, dispatch }, { id }) {
        try {
            const { data } = await Http.instance.get(`/bo/stores/${id}`);
            commit(
                "commons/UPDATE_MERCHANTS_GLOBAL",
                { ...data, merchantId: id, merchantType: "store" },
                { root: true }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_STORES", parseError);
            throw parseError;
        }
    },
    async getStoreOwnById({ commit, dispatch }, { id }) {
        try {
            const { data } = await Http.instance.get(`/bo/stores/own/${id}`);
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_STORES", parseError);
            throw parseError;
        }
    },
    async createStoreOwn(
        { commit, dispatch },
        {
            address,
            addressComplement,
            cityCode,
            coordinates,
            name,
            neighborhood,
            phone,
            tin
        }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/stores/own", {
                tin,
                name,
                phone,
                address,
                coordinates,
                cityCode,
                neighborhood,
                addressComplement
            });
            commit("commons/SET_MERCHANTS_OWN", [data], { root: true });
            dispatch("auth/applyMerchantScopes", data.scopes, { root: true });
            commit("commons/SET_MERCHANT_SELECTED", data, { root: true });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_STORES", parseError);
            throw parseError;
        }
    },
    async updateStoreOwn(
        { commit, dispatch },
        {
            address,
            addressComplement,
            cityCode,
            coordinates,
            id,
            name,
            neighborhood,
            phone,
            tin
        }
    ) {
        try {
            const { data } = await Http.instance.patch("/bo/stores/own", {
                id,
                tin,
                name,
                phone,
                address,
                coordinates,
                cityCode,
                neighborhood,
                addressComplement
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_STORES", parseError);
            throw parseError;
        }
    },
    async createStore(
        { commit, dispatch },
        {
            address,
            addressComplement,
            cityCode,
            communicationConfig,
            coordinates,
            description,
            images,
            keywords,
            name,
            neighborhood,
            phone,
            shortDescription,
            slug,
            tin
        }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/stores", {
                tin,
                name,
                phone,
                slug,
                images,
                address,
                coordinates,
                cityCode,
                keywords,
                description,
                neighborhood,
                shortDescription,
                addressComplement,
                communicationConfig
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_STORES", parseError);
            throw parseError;
        }
    },
    async updateStore(
        { commit, dispatch },
        {
            address,
            addressComplement,
            cityCode,
            communicationConfig,
            coordinates,
            description,
            id,
            images,
            keywords,
            name,
            neighborhood,
            phone,
            shortDescription,
            slug,
            tin
        }
    ) {
        try {
            const { data } = await Http.instance.patch("/bo/stores/", {
                id,
                tin,
                name,
                phone,
                slug,
                images,
                address,
                coordinates,
                cityCode,
                keywords,
                description,
                communicationConfig,
                neighborhood,
                shortDescription,
                addressComplement
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_STORES", parseError);
            throw parseError;
        }
    },
    async updateStoreMoney({ commit, dispatch }, { id, moneyConfig }) {
        try {
            const { data } = await Http.instance.put(`/bo/stores/${id}/money`, {
                moneyConfig
            });
            commit(
                "commons/UPDATE_MERCHANTS_GLOBAL",
                { merchantId: id, merchantType: "store", moneyConfig },
                { root: true }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_STORES", parseError);
            throw parseError;
        }
    },
    async updateStoreServices({ commit, dispatch }, { id, services }) {
        try {
            const { data } = await Http.instance.put(
                `/bo/stores/${id}/services`,
                {
                    services
                }
            );
            commit(
                "commons/UPDATE_MERCHANTS_GLOBAL",
                { merchantId: id, merchantType: "store", services },
                { root: true }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_STORES", parseError);
            throw parseError;
        }
    },
    async setStoreActive(
        { commit, dispatch },
        { enabledForDeliveries, id, weight }
    ) {
        try {
            const { data } = await Http.instance.put(
                `/bo/stores/${id}/active`,
                {
                    enabledForDeliveries,
                    weight
                }
            );
            commit(
                "commons/UPDATE_MERCHANTS_GLOBAL",
                {
                    merchantId: id,
                    merchantType: "store",
                    enabledForDeliveries,
                    weight
                },
                { root: true }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_STORES", parseError);
            throw parseError;
        }
    },
    async transferStore({ commit, dispatch }, { id, isOwn }) {
        try {
            const { data } = await Http.instance.put(
                `/bo/stores/${id}/transfer`,
                {
                    isOwn
                }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_STORES", parseError);
            throw parseError;
        }
    },
    async deleteStore({ commit, dispatch }, { definitely = false, id }) {
        try {
            await Http.instance.delete(`/bo/stores/${id}`, {
                params: { definitely }
            });
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_STORES", parseError);
            throw parseError;
        }
    }
};

export default actions;
